<template>
    <div>
        <div class="border-t">
            <Breadcrumb />
        </div>
        <div class="mt-5 mx-5">
            <keep-alive :exclude="exclude">
                <router-view class="mt-2 mb-2"></router-view>
            </keep-alive>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Breadcrumb from "../../../components/Breadcrumb.vue";
export default {
    components: {
        Breadcrumb,
    },
    computed: {
        ...mapState("i18nStore", ["langDatas"]),
    },
    data() {
        return {
            exclude: [
                "AccountDetail",
                "OrderDetail",
                "AddProvider",
                "Chats",
                "ProviderList",
                "MemberList",
                "CustomerComplaintDetail",
                "Breadcrumb",
                "PermissionRouteList",
                "PermissionRouteForm",
                "PermissionRoleList",
                "PermissionRoleForm",
                "PermissionUserList",
                "PermissionUserForm",
                "ProviderSalaryPhoto",
                "ProviderPhotos",
                "MemberPhotos",
            ],
        };
    },
    beforeRouteLeave(to, from, next) {
        // location.reload();
        return next();
    },
};
</script>
